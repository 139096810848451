<template>
  <div class="wrapper">
    <sidebar />
    <div class="main-content">
      <div class="container">
        <navbar />
        <loader
          v-show="getCompLoaderStatus"
          :is-comp-loader="getCompLoaderStatus"
        />
        <questionnaire v-show="!getCompLoaderStatus" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "./Sidebar.vue";
import Navbar from "./Navbar.vue";
import Loader from "./Loader.vue";
import Questionnaire from "./Questionnaire.vue";

export default {
  name: "Layout",
  components: {
    sidebar: Sidebar,
    navbar: Navbar,
    loader: Loader,
    questionnaire: Questionnaire,
  },
  computed: {
    ...mapGetters(["getCompLoaderStatus"]),
  },
};
</script>
