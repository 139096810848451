<template>
  <div>
    <div class="form-group">
      <div class="question">
        <h2 v-if="getQueId">{{ getUserNameWithQueText }}</h2>
        <h2 v-else>{{ question.groupName }}</h2>
        <p class="mt-3" v-show="question.questionDescription">
          {{ question.questionDescription }}
        </p>
        <p class="personal-info mt-3" v-show="question.subGroupName">
          {{ question.subGroupName }}
        </p>
      </div>
      <div class="row">
        <template v-for="(que, index) in theQuestions">
          <transition :key="index" name="fade">
            <template v-if="checkLocalDependancy(que)">
              <div :class="que.questionCSSClass">
                <form @submit.prevent>
                  <div class="form-group">
                    <template v-if="index === 0">
                      <label
                        v-if="additionalInfo && !employmentId"
                        :for="que.questionText"
                        class="form-label"
                        >{{ que.questionText }}
                        <small v-if="que.isMandatory" class="asterisk-mark"
                          >*</small
                        >
                      </label>

                      <div
                        class="answer"
                        v-if="
                          additionalInfo &&
                          !employmentId &&
                          que.questionType === 'Picklist'
                        "
                      >
                        <div class="d-flex">
                          <div
                            class="small-radio pe-3"
                            v-for="(option, i) in que.questionOptions"
                            :key="i"
                          >
                            <input
                              v-if="que.questionType === 'Picklist'"
                              type="radio"
                              :id="option.optionValue + que.questionId"
                              :value="option.optionValue"
                              v-model.trim="theAnswers[index]"
                            />
                            <label :for="option.optionValue + que.questionId">
                              <img
                                v-if="option.optionSvgLink"
                                class="dynamic-color-icons"
                                :src="option.optionSvgLink"
                              />
                              <span>{{ option.optionName }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </template>

                    <template
                      v-else-if="
                        que.questionFieldMapping === 'end_date__c' ||
                        que.questionFieldMapping === 'candidoem__end_date__c'
                      "
                    >
                      <label
                        v-if="!hideEndDate"
                        :for="que.questionText"
                        class="form-label"
                        >{{ que.questionText }}
                        <small v-if="que.isMandatory" class="asterisk-mark"
                          >*</small
                        >
                      </label>
                      <input
                        v-if="!hideEndDate"
                        :type="getFieldType(que)"
                        :inputmode="getInputMode(que)"
                        v-model.trim="theAnswers[index]"
                        class="form-control"
                        :id="que.questionText"
                        :placeholder="que.questionText"
                      />
                    </template>

                    <template v-else>
                      <!-- Label for Input Types -->
                      <label :for="que.questionText" class="form-label"
                        >{{ que.questionText }}
                        <small v-if="que.isMandatory" class="asterisk-mark"
                          >*</small
                        >
                      </label>
                      <!-- Address field with Google Places API -->
                      <vue-google-autocomplete
                        v-if="
                          que.questionFieldMapping === 'street__c' ||
                          que.questionFieldMapping === 'candidoem__street__c'
                        "
                        class="form-control"
                        ref="employmentAddress"
                        id="map"
                        v-on:placechanged="pullAddressFields"
                        v-on:inputChange="pullAddressFields"
                        :placeholder="que.questionText"
                        v-model="theAnswers[index]"
                        country="us"
                        :fields="[
                          'formatted_address',
                          'address_components',
                          'geometry',
                        ]"
                      >
                      </vue-google-autocomplete>

                      <div v-else>
                        <!-- Type: Picklist OR Checkbox -->
                        <template
                          v-if="
                            que.questionType === 'Picklist' ||
                            que.questionType === 'Checkbox'
                          "
                        >
                          <div class="answer">
                            <div class="d-flex">
                              <div
                                class="small-radio pe-3"
                                v-for="(option, i) in que.questionOptions"
                                :key="i"
                              >
                                <input
                                  v-if="que.questionType === 'Picklist'"
                                  type="radio"
                                  :id="option.optionValue + que.questionId"
                                  :value="option.optionValue"
                                  v-model.trim="theAnswers[index]"
                                />
                                <input
                                  v-if="que.questionType === 'Checkbox'"
                                  type="radio"
                                  :id="option.optionValue + que.questionId"
                                  :value="
                                    option.optionValue === 'Yes' ? true : false
                                  "
                                  v-model.trim="theAnswers[index]"
                                />
                                <label
                                  :for="option.optionValue + que.questionId"
                                >
                                  <img
                                    v-if="option.optionSvgLink"
                                    class="dynamic-color-icons"
                                    :src="option.optionSvgLink"
                                  />
                                  <span>{{ option.optionName }}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </template>

                        <!-- Type: Dropdown -->
                        <template v-else-if="que.questionType === 'Dropdown'">
                          <select
                            class="form-select"
                            v-model.trim="theAnswers[index]"
                          >
                            <option
                              v-for="(option, index) in que.questionOptions"
                              :key="index"
                            >
                              {{ option.optionName }}
                            </option>
                          </select>
                        </template>

                        <!-- Type: Input Fields -->
                        <template v-else>
                          <input
                            v-if="
                              que.questionFieldMapping ===
                                'years_in_profession__c' ||
                              que.questionFieldMapping ===
                                'candidoem__years_in_profession__c' ||
                              que.questionFieldMapping ===
                                'years_of_learning__c' ||
                              que.questionFieldMapping ===
                                'candidoem__years_of_learning__c'
                            "
                            :type="getFieldType(que)"
                            maxlength="2"
                            v-model.trim="theAnswers[index]"
                            inputmode="numeric"
                            class="form-control"
                            :id="que.questionText"
                            :placeholder="que.questionText"
                            @input="
                              theAnswers[index] = theAnswers[index]
                                .replace(/[^0-9]/g, '')
                                .replace(/(\..*?)\..*/g, '$1')
                                .replace(/^0[^.]/, '0')
                            "
                          />

                          <div
                            v-else-if="
                              inputFieldsWithIcons.includes(que.questionType)
                            "
                            class="input-group two-input-group"
                          >
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                :id="'basic-addon' + index"
                                ><i :class="getIcons(que.questionType)"></i
                              ></span>
                            </div>
                            <input
                              :type="getFieldType(que)"
                              :inputmode="getInputMode(que)"
                              class="form-control"
                              :id="que.questionText"
                              :placeholder="que.questionText"
                              v-model="theAnswers[index]"
                            />
                            <div
                              class="input-group-append"
                              v-if="
                                getFieldType(que) === 'password' ||
                                getFieldType(que) === 'show-password'
                              "
                            >
                              <span
                                @click="showInput(index)"
                                class="input-group-text input-group-eye bg-transparent"
                                ><i
                                  :class="
                                    getFieldType(que) === 'show-password'
                                      ? 'far fa-eye'
                                      : 'fas fa-eye-slash'
                                  "
                                ></i
                              ></span>
                            </div>
                          </div>

                          <input
                            v-else
                            :type="getFieldType(que)"
                            :inputmode="getInputMode(que)"
                            class="form-control"
                            @blur="
                              que.questionType === 'Phone'
                                ? maskNumber($event, index)
                                : ''
                            "
                            :id="que.questionText"
                            :placeholder="que.questionText"
                            v-model.trim="theAnswers[index]"
                            @input="
                              que.questionType === 'Email'
                                ? checkEmail(theAnswers[index])
                                : que.questionType === 'Phone'
                                ? maskNumber($event, index)
                                : ''
                            "
                          />

                          <small
                            class="text-danger"
                            v-if="que.questionType === 'Email' && !validEmail"
                          >
                            Please enter a valid email address.</small
                          >
                          <small
                            class="text-danger"
                            v-if="
                              que.questionType === 'Phone' && !validPhoneNumber
                            "
                          >
                            Please enter a 10 digit mobile number.</small
                          >
                        </template>
                      </div>
                    </template>
                  </div>
                </form>
              </div>
            </template>
          </transition>
        </template>
      </div>
    </div>
    <div class="button">
      <button
        type="button"
        class="btn btn-outline"
        @click="userEmployments.length ? viewEmployments() : previousQuestion()"
      >
        <span>Previous</span>
      </button>
      <button
        type="button"
        class="btn"
        :class="checkTheValidation ? 'btn-red' : ''"
        :disabled="!checkTheValidation"
        @click="saveTheData"
      >
        <span>Continue</span>
      </button>
    </div>
  </div>
</template>

<script>
import { eventBus } from "./../../event-bus";
import commonFunctions from "./../../mixins/commonfunctions";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";

export default {
  name: "EmploymentFields",
  mixins: [commonFunctions],
  components: { VueGoogleAutocomplete },
  data() {
    return {
      theQuestions: [],
      theAnswers: [],
      preventNextIteration: false,
      theAddress: "",
      streetNumber: "",
      theRoute: "",
      theCity: "",
      theState: "",
      theCountry: "",
      postalCode: "",
      fullAddress: "",
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
    employmentId: {
      type: String,
    },
    userEmployments: {
      type: Array,
      required: true,
    },
    additionalInfo: {
      type: Boolean,
      required: true,
    },
    currentEmployer: {
      type: Number,
      required: true,
    },
  },
  computed: {
    checkTheValidation() {
      let theArr = [];
      let ques = this.theQuestions;
      let answers = this.theAnswers;

      for (let i = 0; i < ques.length; i++) {
        if (this.checkLocalDependancy(ques[i])) {
          if (
            ques[i].isMandatory &&
            (answers[i] === undefined ||
              answers[i] === "" ||
              answers[i] === null)
          )
            theArr.push(true);
          else if (
            ques[i].questionType === "Date" &&
            answers[i] === "Invalid date"
          )
            theArr.push(true);
          else theArr.push(false);
        }
      }

      return (
        theArr.every((v) => v === false) &&
        this.validEmail &&
        this.validPhoneNumber
      );
    },

    getQueId() {
      let checkCoApplicant = this.getQuestions.some(
        (v) =>
          (v.questionFieldMapping === "is_joint_applicant_added__c" ||
            v.questionFieldMapping ===
              "candidoem__is_joint_applicant_added__c") &&
          v.questionAnswer === "Yes"
      );

      if (checkCoApplicant) {
        let string = this.question.groupName;
        let question_id;

        if (string) {
          question_id = string.substring(
            string.indexOf("{") + 1,
            string.lastIndexOf("}")
          );
        }

        return question_id || false;
      }

      return false;
    },

    getUserNameWithQueText() {
      if (this.getQueId) {
        let string = this.question.groupName;
        let question_id = string.substring(
          string.indexOf("{"),
          string.lastIndexOf("}") + 1
        );

        let get_name = this.getQuestions.find(
          (v) => v.questionId === this.getQueId
        );

        let que_text = get_name
          ? string.replace(question_id, get_name.questionAnswer)
          : string;

        return que_text || "";
      }

      return "";
    },

    editModeAddress() {
      return (
        this.userEmployments.find(
          (x) => x.employmentId === this.employmentId
        ) || null
      );
    },

    hideEndDate() {
      if (!this.userEmployments.length) return true;

      if (
        this.employmentId &&
        this.userEmployments[this.currentEmployer].employmentInfoId ===
          this.employmentId
      )
        return true;

      return false;
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
  },
  beforeMount() {
    this.mergeTheQuestions();
  },
  mounted() {
    this.checkTheDependancy(this.question);
    this.prePopulateTheAnswers();
  },
  methods: {
    checkLocalDependancy(que) {
      if (que.dependentQuestionId) {
        let checkLocal = this.getQuestions.find(
          (m) => m.questionId === que.dependentQuestionId
        );

        if (checkLocal) {
          if (checkLocal.groupName === que.groupName) {
            let getTheIndex = this.theQuestions.findIndex(
              (v) => v.questionId === que.dependentQuestionId
            );

            if (
              getTheIndex > -1 &&
              que.dependentQuestionAnswer === this.theAnswers[getTheIndex]
            ) {
              if (que.defaultQuestionAnswer) {
                let theQueIndex = this.theQuestions.findIndex(
                  (v) => v.questionId === que.questionId
                );

                this.theAnswers[theQueIndex] =
                  que.questionAnswer || que.defaultQuestionAnswer;
              }

              return true;
            } else {
              let getQueIndex = this.theQuestions.findIndex(
                (v) => v.questionId === que.questionId
              );

              if (getQueIndex) this.theAnswers[getQueIndex] = "";
              return false;
            }
          } else {
            if (checkLocal.questionType === "MultipleChoice") {
              let theArr = que.dependentQuestionAnswer.split("||");

              if (theArr.length) {
                if (
                  checkLocal.questionAnswer?.length &&
                  checkLocal.questionAnswer.some((r) => theArr.includes(r))
                )
                  return true;
                else return false;
              }
            } else {
              if (checkLocal.questionAnswer === que.dependentQuestionAnswer)
                return true;
              else return false;
            }
          }
        }
      }

      return true;
    },

    maskNumber(event, index) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }

      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }

      if (this.theAnswers[index] && this.theAnswers[index].length) {
        let x = this.theAnswers[index]
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        this.theAnswers[index] =
          x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
      }

      this.checkPhoneNumber(this.theAnswers[index]);
    },

    showInput(index) {
      let queType = this.theQuestions[index].questionType;
      this.theQuestions[index].questionType =
        queType === "Password" ? "Show-Password" : "Password";
    },

    viewEmployments() {
      eventBus.$emit("show-emp-fields", false);
    },

    async saveTheData() {
      this.fetchCompLoaderStatus(true);

      this.theQuestions.forEach((v, i) => {
        v.questionAnswer = this.theAnswers[i] || null;
      });

      // API for address
      if (this.getUserId) {
        // payLoad for API
        let payLoad = {
          prospectId: this.getUserId,
          flowName: this.getAppFlow,
          employmentInfoDetails: [
            {
              employmentInfoData: this.theQuestions,
              employmentInfoId: this.employmentId || null,
            },
          ],
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/employmentInfo/createAndUpdateEmploymentInfo/", payLoad)
          .then(async (response) => {
            let resp = response.data;

            if (resp.response === "Success") await this.getEmploymentInfo();
            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      this.nextQuestion();
    },

    nextQuestion() {
      eventBus.$emit("show-emp-fields", false);
      this.fetchCompLoaderStatus(false);
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.theQuestions[0].questionIndex - 1);
    },

    mergeTheQuestions() {
      let theFields = [];

      for (
        let i = this.getActiveQuestion - 1;
        i < this.getQuestions.length;
        i++
      ) {
        if (
          this.getQuestions[i].groupName === "" ||
          this.getQuestions[i].groupName !== this.question.groupName
        ) {
          break;
        }

        theFields.push(this.getQuestions[i]);
      }

      this.theQuestions = theFields;
    },

    prePopulateTheAnswers() {
      // If logged in: Pre-populate user stored answers
      if (this.getIsAuthenticated && this.employmentId) {
        let getAddr;

        getAddr = this.userEmployments.find(
          (v) => v.employmentInfoId === this.employmentId
        );

        if (getAddr) {
          for (let i = 0; i < this.theQuestions.length; i++) {
            for (let j = 0; j < getAddr.employmentInfoData.length; j++) {
              if (
                this.theQuestions[i].questionId ===
                getAddr.employmentInfoData[j].questionId
              ) {
                let s = getAddr.employmentInfoData[j];

                let answer;

                if (s.questionType === "Date") {
                  answer = s.questionAnswer
                    ? moment(s.questionAnswer).format("YYYY-MM-DD")
                    : s.defaultQuestionAnswer
                    ? moment(s.defaultQuestionAnswer).format("YYYY-MM-DD")
                    : undefined;
                } else if (s.questionType === "Checkbox") {
                  answer =
                    s.questionAnswer === null || s.questionAnswer === ""
                      ? s.defaultQuestionAnswer
                        ? s.defaultQuestionAnswer === "false"
                          ? false
                          : true
                        : undefined
                      : s.questionAnswer === "false"
                      ? false
                      : true;
                } else if (
                  s.questionFieldMapping === "street__c" ||
                  s.questionFieldMapping === "candidoem__street__c"
                ) {
                  if (
                    s.questionAnswer.lastIndexOf("#") ===
                    s.questionAnswer.length - 1
                  ) {
                    s.questionAnswer = s.questionAnswer
                      .replaceAll("#", "")
                      .trim()
                      .slice(0, -1);
                  }

                  answer = s.questionAnswer || undefined;
                } else {
                  answer =
                    s.questionAnswer || s.defaultQuestionAnswer || undefined;
                }

                this.$set(this.theAnswers, i, answer);
              }
            }
          }
        }
      }

      if (this.getIsAuthenticated && !this.employmentId) {
        this.theQuestions.forEach((v, i) => {
          if (
            v.questionFieldMapping === "end_date__c" ||
            v.questionFieldMapping === "candidoem__end_date__c"
          ) {
            if (!this.additionalInfo) {
              this.$set(this.theAnswers, i, moment().format("YYYY-MM-DD"));
            } else {
              const startDateObject = this.userEmployments
                .at(-1)
                .employmentInfoData.find(
                  (x) =>
                    x.questionFieldMapping === "start_date__c" ||
                    x.questionFieldMapping === "candidoem__start_date__c"
                );

              const predictedEndDate = moment(startDateObject.questionAnswer)
                .subtract(1, "days")
                .format("YYYY-MM-DD");

              this.$set(this.theAnswers, i, predictedEndDate);
            }
          } else if (v.defaultQuestionAnswer)
            this.$set(this.theAnswers, i, v.defaultQuestionAnswer);
        });
      }
    },

    pullAddressFields(addressData) {
      if (addressData.newVal) {
        let street_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "street__c" ||
            v.questionFieldMapping === "candidoem__street__c"
        );

        let fullStreet = addressData.newVal.split(",")[0];

        this.$set(this.theAnswers, street_index, fullStreet);
        this.$refs.employmentAddress[0].$refs.autocomplete.value = fullStreet;
      } else {
        let street_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "street__c" ||
            v.questionFieldMapping === "candidoem__street__c"
        );

        let streetNumber = addressData.street_number;
        let streetName = addressData.route;

        let fullStreet;

        if (streetNumber && streetName)
          fullStreet = `${streetNumber} ${streetName}`;
        else {
          if (streetNumber) fullStreet = `${streetNumber}`;
          if (streetName) fullStreet = `${streetName}`;
        }

        this.$set(this.theAnswers, street_index, fullStreet);
        this.$refs.employmentAddress[0].$refs.autocomplete.value = fullStreet;

        let city_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "city__c" ||
            v.questionFieldMapping === "candidoem__city__c"
        );

        let state_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "state__c" ||
            v.questionFieldMapping === "candidoem__state__c"
        );

        let zipcode_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "postalcode__c" ||
            v.questionFieldMapping === "candidoem__postalcode__c"
        );

        let city = addressData.locality;
        let state = addressData.administrative_area_level_1;
        let zipcode = addressData.postal_code;
        let latitude = addressData.latitude;
        let longitude = addressData.longitude;

        if (city) {
          this.$set(this.theAnswers, city_index, city);
        } else {
          this.$set(this.theAnswers, city_index, undefined);
        }

        if (state) {
          this.$set(this.theAnswers, state_index, state);
          this.validStateCode = true;
        } else {
          this.$set(this.theAnswers, state_index, undefined);
        }

        if (zipcode) {
          this.checkZipCode(zipcode, false);
          this.$set(this.theAnswers, zipcode_index, zipcode);
        } else {
          if (latitude && longitude) {
            this.$http
              .get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&result_type=postal_code&key=AIzaSyDYYKos_zgje0clujI_sKRXYsPrDo1k2FY`
              )
              .then((response) => {
                let resp = response.data;

                if (resp.status === "OK") {
                  let getZip = resp.results[0].address_components.find(
                    (el) => el.types[0] === "postal_code"
                  );

                  if (getZip) {
                    this.checkZipCode(getZip.long_name, false);
                    this.$set(this.theAnswers, zipcode_index, getZip.long_name);
                    return;
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

          this.$set(this.theAnswers, zipcode_index, undefined);
        }
      }
    },

    async getEmploymentInfo() {
      await this.$http
        .get(
          "/employmentInfo/GetAllEmploymentInfo/" +
            this.getUserId +
            "?flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchAllEmployment(resp.employmentInfoData || []);
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
