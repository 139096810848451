<template>
  <div class="form-element">
    <div class="sign-in-up forgot-password" id="reset-password">
      <div class="overlay"></div>
      <div class="form">
        <div class="form__content">
          <div class="lock-icon">
            <span>
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.81592 2.25974C4.12462 3.48872 4 4.95088 4 6H3C1.34315 6 0 7.34315 0 9V17C0 18.6569 1.34315 20 3 20H15C16.6569 20 18 18.6569 18 17V9C18 7.34315 16.6569 6 15 6L14 5.99998C14 4.95087 13.8754 3.48871 13.1841 2.25973C12.829 1.62845 12.3194 1.05012 11.6031 0.63486C10.8875 0.220048 10.021 0 9 0C7.97899 0 7.11246 0.220048 6.39691 0.63486C5.68058 1.05012 5.17102 1.62845 4.81592 2.25974ZM6.55908 3.24026C6.12538 4.01128 6 5.04912 6 6H12C12 5.04911 11.8746 4.01129 11.4409 3.24027C11.2335 2.87155 10.9618 2.57488 10.6 2.36514C10.2375 2.15495 9.72899 2 9 2C8.27101 2 7.76254 2.15495 7.39997 2.36514C7.03817 2.57488 6.76648 2.87155 6.55908 3.24026ZM11 12C11 12.7403 10.5978 13.3866 10 13.7324V15C10 15.5523 9.55228 16 9 16C8.44772 16 8 15.5523 8 15V13.7324C7.4022 13.3866 7 12.7403 7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12Z"
                  :fill="getAssets.primaryBrandColor || '#BE2530'"
                />
              </svg>
            </span>
          </div>
          <h2>Create new password</h2>

          <p>Enter a new password for your account</p>
          <form
            class="text-left"
            @submit.prevent="resetPassword"
            @keyup.enter="resetPassword"
          >
            <div class="form-group">
              <label class="form-label"
                >Enter six-digit Verification Code sent to your email</label
              >
              <input
                v-model.trim="$v.theCode.$model"
                type="text"
                inputmode="numeric"
                maxlength="6"
                class="form-control"
                :class="$v.theCode.$error || !checkOTP ? 'is-invalid' : ''"
                placeholder="Six-digit code"
                @input="
                  theCode = theCode
                    .replace(/[^0-9]/g, '')
                    .replace(/(\..*?)\..*/g, '$1')
                    .replace(/^0[^.]/, '0')
                "
              />
              <small class="invalid-feedback" v-if="!$v.theCode.required"
                >Six digit Verification Code is required.</small
              >
              <small class="invalid-feedback" v-else-if="!$v.theCode.minLength"
                >Verification Code must be a six-digit code.</small
              >
              <small class="invalid-feedback" v-else-if="!checkOTP"
                >Please enter correct Verification Code.</small
              >
            </div>
            <div class="form-group">
              <label class="form-label">New password</label>
              <div class="input-group">
                <input
                  v-model.trim="$v.password.$model"
                  :type="showPass ? 'text' : 'password'"
                  class="form-control"
                  :class="$v.password.$error ? 'is-invalid' : ''"
                />
                <div class="input-group-append">
                  <span
                    @click="showPass = !showPass"
                    class="input-group-text input-group-eye bg-transparent"
                    ><i
                      :class="showPass ? 'far fa-eye' : 'fas fa-eye-slash'"
                    ></i
                  ></span>
                </div>
              </div>
              <div v-if="$v.password.$error">
                <small
                  v-if="!$v.password.required"
                  class="invalid-feedback d-block"
                  >Password field is required.</small
                >
                <small
                  v-else-if="!$v.password.maxLength"
                  class="d-block invalid-feedback"
                  >Maximum {{ $v.password.$params.maxLength.max }} characters
                  are allowed.</small
                >
              </div>
            </div>
            <div class="form-group mb-4 pb-3">
              <label class="form-label">Confirm new password</label>
              <div class="input-group">
                <input
                  v-model.trim="$v.confirmPassword.$model"
                  :type="showCoPass ? 'text' : 'password'"
                  class="form-control"
                  :class="$v.confirmPassword.$error ? 'is-invalid' : ''"
                />
                <div class="input-group-append">
                  <span
                    @click="showCoPass = !showCoPass"
                    class="input-group-text input-group-eye bg-transparent"
                    ><i
                      :class="showPass ? 'far fa-eye' : 'fas fa-eye-slash'"
                    ></i
                  ></span>
                </div>
              </div>
              <div v-if="$v.confirmPassword.$error">
                <small
                  v-if="!$v.confirmPassword.required"
                  class="d-block invalid-feedback"
                  >Confirm Password field is required.</small
                >
                <small
                  v-else-if="!$v.confirmPassword.sameAs"
                  class="d-block invalid-feedback"
                  >Password and Confirm Password fields does not match.</small
                >
              </div>
            </div>
            <div class="button w-100">
              <button
                type="button"
                class="btn w-100"
                :class="!$v.$invalid ? 'btn-red' : ''"
                :disabled="$v.$invalid"
                @click="resetPassword"
              >
                <span>Reset Password</span>
              </button>
            </div>
            <div class="button w-100 mt-3">
              <button
                @click="showSignIn()"
                type="button"
                class="btn btn-cancel w-100"
              >
                <span>Cancel</span>
              </button>
            </div>
            <div class="account-text">
              <p>
                Don't have an account?
                <button
                  type="button"
                  class="custom-underline-text"
                  @click="showSignUp()"
                >
                  <strong>Sign up</strong>
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  name: "Reset-Password",
  data() {
    return {
      theCode: "",
      password: "",
      confirmPassword: "",
      checkOTP: true,
      showPass: false,
      showCoPass: false,
    };
  },
  validations: {
    theCode: {
      required,
      minLength: minLength(6),
    },
    password: {
      required,
      maxLength: maxLength(25),
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },
  },
  watch: {
    theCode(newVal) {
      if (newVal) {
        if (newVal === window.atob(this.getForgotPassDetails.prospectOTPCode)) {
          this.checkOTP = true;
        } else this.checkOTP = false;
      }
    },
  },
  mounted() {
    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters([
      "getAssets",
      "getCompanyName",
      "getProspectId",
      "getOrgId",
      "getQuestions",
      "getForgotPassDetails",
      "getSetupPassword",
    ]),

    baseUrl() {
      return (
        "/" +
        this.getCompanyName +
        "/" +
        this.getProspectId +
        "&" +
        this.getOrgId
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchLoaderStatus",
      "fetchCompLoaderStatus",
      "fetchSetupPassword",
    ]),

    showSignIn() {
      this.$emit("show-signin", true);
    },

    showSignUp() {
      this.$emit("show-signup", true);
    },

    goBack() {
      if (this.getSetupPassword) {
        this.fetchSetupPassword(false);
        this.$router.push(this.baseUrl);
      } else {
        this.$router.push("/sign-in");
      }
    },

    async resetPassword() {
      this.fetchCompLoaderStatus(true);

      let payLoad = {
        email: "",
        password: this.password,
        prospectId: this.getForgotPassDetails.prospectId,
        orgId: this.getOrgId,
      };

      await this.$http
        .post("/account/UpdateUser/", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success("Password updated successfully");

            this.password = "";
            this.confirmPassword = "";
            this.$v.$reset();
            this.fetchSetupPassword(false);
            this.showSignIn();
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchCompLoaderStatus(false);
    },
  },
};
</script>

<style scoped >
.sign-in-up .form {
  margin: 45px 0 !important;
}

.text-left {
  text-align: left;
}

.sign-in-up.forgot-password .form .form__content form {
  padding: 30px 0px 0 !important;
}

.sign-in-up .form .form__content {
  padding: 0;
  max-width: 400px;
  margin: 0;
}

.forgot-password {
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-color);
  width: 100%;
  text-align: right;
}

.sign-in-up .form .form__content {
  border: none;
  box-shadow: none;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

form .flex-nowrap {
  margin-bottom: 10px;
}

form .flex-nowrap .form-label {
  margin-bottom: 0;
}

.custom-underline-text {
  text-decoration: underline;
  font-size: 14px;
}
</style>