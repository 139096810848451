var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showEmpFields)?_c('employment-fields',{attrs:{"question":_vm.question,"currentaq":_vm.currentaq,"previousaq":_vm.previousaq,"employmentId":_vm.currentEmploymentId,"userEmployments":_vm.userEmployments,"additionalInfo":_vm.showPreviousEmployerButton,"currentEmployer":_vm.getCurrentEmployer()}}):_vm._e(),(!_vm.showEmpFields)?_c('form',{staticClass:"property"},[_c('div',{staticClass:"question"},[(_vm.getQueId)?_c('h2',[_vm._v(_vm._s(_vm.getUserNameWithQueText))]):_c('h2',[_vm._v(_vm._s(_vm.question.groupName))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.question.questionDescription),expression:"question.questionDescription"}],staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.question.questionDescription)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.question.subGroupName),expression:"question.subGroupName"}],staticClass:"personal-info mt-3"},[_vm._v(" "+_vm._s(_vm.question.subGroupName)+" ")])]),_c('ul',[_vm._l((_vm.userEmployments),function(emp,index){return _c('li',{key:index},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.fetchTheTitle(emp)))]),(
              emp.detailType === 'Employer Information' &&
              index === _vm.getCurrentEmployer()
            )?_c('span',{staticClass:"text-red"},[_c('strong',[_vm._v("Your current employer")])]):_vm._e()]),_c('p',[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.formTheAddress(emp.employmentInfoData)))])])]),_vm._l((emp.employmentInfoData),function(subs,i){return _c('p',{key:i},[(
              _vm.checkLocalDependancy(subs, emp.employmentInfoData) &&
              subs.questionFieldMapping !== 'employer_name__c' &&
              subs.questionFieldMapping !== 'candidoem__employer_name__c' &&
              subs.questionFieldMapping !== 'name' &&
              subs.questionFieldMapping !== 'candidoem__name'
            )?[(subs.questionType === 'Text' && subs.questionAnswer)?_c('span',[(
                  subs.questionFieldMapping !== 'street__c' &&
                  subs.questionFieldMapping !== 'candidoem__street__c' &&
                  subs.questionFieldMapping === 'apt_unit__c' &&
                  subs.questionFieldMapping === 'candidoem__apt_unit__c' &&
                  subs.questionFieldMapping !== 'city__c' &&
                  subs.questionFieldMapping !== 'candidoem__city__c' &&
                  subs.questionFieldMapping !== 'state__c' &&
                  subs.questionFieldMapping !== 'candidoem__state__c' &&
                  subs.questionFieldMapping !== 'postalcode__c' &&
                  subs.questionFieldMapping !== 'candidoem__postalcode__c'
                )?[_c('span',[_vm._v(_vm._s(subs.questionText)+":")]),_c('strong',[_vm._v(" "+_vm._s(subs.questionAnswer))])]:_vm._e()],2):(subs.questionType === 'Date' && subs.questionAnswer)?_c('span',[(
                  subs.questionFieldMapping === 'end_date__c' ||
                  subs.questionFieldMapping === 'candidoem__end_date__c'
                )?[(index !== _vm.getCurrentEmployer())?[_vm._v(" "+_vm._s(subs.questionText)+": "),_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(subs.questionAnswer)))])]:_vm._e()]:[_vm._v(" "+_vm._s(subs.questionText)+": "),_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(subs.questionAnswer)))])]],2):(subs.questionType === 'Phone' && subs.questionAnswer)?_c('span',[_vm._v(_vm._s(subs.questionText)+": "),_c('strong',[_vm._v(" "+_vm._s(subs.questionAnswer))])]):_vm._e()]:_vm._e()],2)}),_c('div',{staticClass:"edit-delete mt-3"},[_c('a',{staticClass:"btn-icon edit cursor-pointer",attrs:{"data-bs-toggle":"modal","data-bs-target":`#deleteModal${emp.employmentInfoId}`}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v("   Delete")]),_vm._v("    "),_c('a',{staticClass:"btn-icon edit cursor-pointer",on:{"click":function($event){_vm.showEmpFields = true;
              _vm.currentEmploymentId = emp.employmentInfoId;}}},[_c('i',{staticClass:"fa fa-pencil-alt"}),_vm._v("   Edit ")])]),_c('div',{staticClass:"modal fade",attrs:{"id":`deleteModal${emp.employmentInfoId}`,"data-bs-backdrop":"static","data-bs-keyboard":"false","tabindex":"-1","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_vm._m(0,true),_c('div',{staticClass:"modal-body"},[_vm._v(" Are you sure you want to delete this record? ")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v(" Close ")]),_c('button',{staticClass:"btn btn-red",attrs:{"type":"button","data-bs-dismiss":"modal"},on:{"click":function($event){return _vm.deleteTheEmployment(emp)}}},[_c('span',[_vm._v("Delete")])])])])])])],2)}),_c('li',[_c('div',{staticClass:"title"},[_c('button',{staticClass:"d-flex align-items-center",attrs:{"type":"button"},on:{"click":function($event){_vm.showEmpFields = true;
              _vm.currentEmploymentId = '';}}},[_vm._m(1),_c('h2',{staticClass:"mb-0"},[_vm._v("Add employer")])])])])],2),_c('div',{staticClass:"button mt-4 pt-3"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button"},on:{"click":_vm.previousQuestion}},[_c('span',[_vm._v("Previous")])]),_c('button',{staticClass:"btn btn-red",attrs:{"type":"button"},on:{"click":_vm.nextQuestion}},[_c('span',[_vm._v("Continue")])])]),(_vm.question.questionLink)?_c('a',{staticClass:"text-underline",attrs:{"data-bs-toggle":_vm.question.questionLink && _vm.question.questionLinkDescription
          ? 'modal'
          : '',"data-bs-target":"#infoModal"}},[_vm._v(_vm._s(_vm.question.questionLink))]):_vm._e(),(_vm.question.questionLink && _vm.question.questionLinkDescription)?_c('info-modal',{attrs:{"title":_vm.question.questionLink,"description":_vm.question.questionLinkDescription}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title text-red",attrs:{"id":"staticBackdropLabel"}},[_vm._v(" Warning!!! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plus-icon"},[_c('i',{staticClass:"fa fa-plus"})])
}]

export { render, staticRenderFns }