<template>
  <div
    class="modal fade consentToUseRecordsModal"
    :id="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>
              Notice and Agreement Regarding Consent to Use Electronic
              Signatures and Records
            </h2>
          </div>
          <div class="content-desc">
            <p>
              <strong>
                To use electronic signatures and receive documents
                electronically in connection with your use of this platform
                (“Platform”), you must read and consent electronically to the
                terms outlined in this document (“eConsent”), which require your
                ability to access and retain electronic documents. This
                eConsent, if you provide it, applies to your use of this
                Platform on any device, including a desktop, laptop, tablet,
                mobile, or other electronic device (“Access Device”), and to any
                document we provide to you in electronic form, which may include
                messages, notices, disclosures, consents, authorizations,
                acknowledgments, and terms and conditions, whether required by
                law or otherwise (“Document(s)”). If you provide eConsent, we
                will be able to provide electronic Documents to you within this
                Platform, in other portals, and/or through other methods we may
                use for delivery of electronic Documents.
              </strong>
            </p>
            <p>
              <a name="_Hlk80088942"></a>
              Please note these additional definitions:
              <em><strong>We</strong></em
              >, <em><strong>our</strong></em
              >, and <em><strong>us</strong></em> means your financial
              institution and its current and future affiliates, successors, and
              assignees as well as CandidApp LLC (“CANDID”) and its current and
              future affiliates; CANDID is the provider of this Platform.
              <em><strong>You</strong></em> and
              <em><strong>your</strong></em> means the person providing this
              eConsent, including any authorized signer, user, representative,
              delegate, and/or service user.
            </p>
            <p>Scope of your eConsent</p>
            <p>
              We must, due to legal and other requirements, provide you with
              certain Documents in writing. In addition, you may need to sign
              certain Documents. Rather than using paper records and ink
              signatures, we may, with your consent, use electronic records and
              signatures throughout our relationship with you.
              <strong>
                Your eConsent will apply to all of the Documents transmitted,
                received, delivered, and/or signed by you and by us in
                connection with your use of this Platform, in other portals,
                and/or through other methods we may use for delivery of
                electronic Documents.
              </strong>
            </p>
            <p>Right to have a paper version of your Documents</p>
            <p>
              We are required to give you certain Documents “in writing,” which
              means you are entitled to receive a paper version of the
              Documents. However, in order to use this Platform, you must
              provide consent for us to provide these Documents to you
              electronically. If you do not provide eConsent, you will not be
              able to use the Platform.
            </p>
            <p>
              If you provide eConsent, your Documents will be delivered
              electronically so that you can access the Documents and retain
              them for future access. If you want to ensure that you continue to
              have access to these Documents, you must save them onto an Access
              Device or external storage device.
            </p>
            <p>
              You have the right to receive a paper version of your Documents,
              even if you have provided eConsent and we deliver the Documents
              electronically. You may have to pay a fee for a paper version
              unless charging a fee is prohibited by applicable law.
            </p>
            <ul>
              <li>
                <p>
                  If you want a paper version of the Documents provided by your
                  financial institution or its affiliates, contact
                  {{ loCompany }} at {{ getUser.phone }}, {{ getUser.email }}.
                </p>
              </li>
              <li>
                <p>
                  If you want a paper version of the Documents provided by
                  CANDID or its affiliates, email info@candid.com.
                </p>
              </li>
            </ul>
            <p>
              In addition, we may, at any time, in our sole discretion, provide
              you with or require you to use a paper version of the Documents,
              even if you have provided eConsent.
            </p>
            <p>Right to withdraw your eConsent</p>
            <p>
              If you have provided eConsent, you have the right to withdraw your
              eConsent at any time and instead receive a paper version of your
              Documents.
            </p>
            <ul>
              <li>
                <p>
                  If you decide to withdraw your eConsent as it relates to your
                  financial institution or its affiliates, contact
                  {{ loCompany }} at {{ getUser.phone }}, {{ getUser.email }}.
                </p>
              </li>
              <li>
                <p>
                  If you decide to withdraw your eConsent as it relates to
                  CANDID or its affiliates, email
                  <a href="mailto:info@candid.inc">info@candid.inc</a>.
                </p>
              </li>
            </ul>
            <p>
              Your eConsent withdrawal will become effective after we have had a
              reasonable opportunity to act upon it. If you withdraw your
              eConsent, you will not be able to use the Platform.
            </p>
            <p>Obligation to update your email address</p>
            <p>
              <strong>
                You must provide us with your own valid and current email
                address where you can receive email, access hyperlinks, and
                access electronic Documents. You must ensure that we always have
                your valid and current email address, even if you have provided
                eConsent and we deliver your Documents electronically through
                the Platform. To update your email address or other contact
                information, contact
              </strong>
              <strong>
                {{ loCompany }} at {{ getUser.phone }},
                {{ getUser.email }} </strong
              ><em><strong>and</strong></em>
              <strong> contact CANDID at info@candid.inc.</strong>
            </p>
            <p>Hardware and software requirements</p>
            <p>
              Before providing eConsent, you must ensure that the hardware and
              software of any Access Device(s) you use meet the requirements
              below.
            </p>
            <ul>
              <li>
                <p>Internet access – 1.5 Mbps per second or better</p>
              </li>
              <li>
                <p>
                  Disc space – Sufficient memory on Access Device or external
                  storage device to retain Documents
                </p>
              </li>
              <li>
                <p>
                  Latest supported version of Adobe Acrobat Reader or Current
                  Version – If you do not have a PDF reader, you may download
                  one for free at:
                  <a href="https://get.adobe.com/reader/">
                    https://get.adobe.com/reader/
                  </a>
                  .
                  <a
                    href="https://files.consumerfinance.gov/f/201503_cfpb_your-home-loan-toolkit-web.pdf"
                    target="_blank"
                  >
                    <strong>Take our PDF test (PDF)</strong>
                  </a>
                  to confirm that you have the PDF reader software required to
                  view and save PDF files.
                </p>
              </li>
              <li>
                <p>Printer - Installed printer to print disclosures</p>
              </li>
              <li>
                <p>SSL encryption enabled</p>
              </li>
              <li>
                <p>
                  An active email address capable of receiving and sending
                  electronic messages
                </p>
              </li>
              <li>
                <p>Javascript enabled</p>
              </li>
              <li>
                <p>Security settings enabled</p>
              </li>
              <li>
                <p>
                  If applicable services are used, a webcam, microphone, and
                  speaker to support a live, real time audio video connection,
                  and a mobile device with text message functionality and a
                  camera
                </p>
              </li>
            </ul>
            <p>
              Any desktop or laptop device you use must meet the following
              additional requirements, as applicable:
            </p>
            <ul>
              <li>
                <p>
                  Operating system – Latest supported version of
                  <strong>Windows</strong> or <strong>mac OS</strong>
                </p>
              </li>
              <li>
                <p>
                  Internet browser – Latest supported version of
                  <strong>Google Chrome</strong>,<strong>
                    Microsoft Internet Explorer</strong
                  >, <strong>Microsoft Edge</strong>,
                  <strong>Mozilla Firefox</strong>, or
                  <strong>Apple Safari</strong>
                </p>
              </li>
            </ul>
            <p>
              Any tablet, mobile, or other device you use must meet the
              following additional requirements, as applicable:
            </p>
            <ul>
              <li>
                <p>
                  iPhone,
                  <a
                    href="https://support.apple.com/guide/iphone/supported-models-iphe3fa5df43/13.0/ios/13.0"
                    target="_blank"
                  >
                    <strong>supported models</strong>
                  </a>
                  , iOS – Latest fully-patched version
                </p>
              </li>
              <li>
                <p>
                  iPad,
                  <a
                    href="https://support.apple.com/guide/ipad/supported-models-ipad213a25b2/ipados"
                    target="_blank"
                  >
                    <strong>supported models</strong>
                  </a>
                  , iOS – Latest fully-patched version
                </p>
              </li>
              <li>
                <p>Android phone, Android OS – Latest fully-patched version</p>
              </li>
              <li>
                <p>
                  Internet browser — Latest supported version of Google Chrome
                  or Apple Safari
                </p>
              </li>
            </ul>
            <p>
              Note: this Platform is optimized for use on Google Chrome. You may
              download Google Chrome for free at:
              <a href="https://www.google.com/chrome/" target="_blank">
                <u>https://www.google.com/chrome/</u>
              </a>
              . If you use other software, you may not receive the same level of
              support or performance, but as long as you are able to read and
              review the information above and otherwise access PDF documents,
              you should be able to access and retain Documents provided through
              this Platform.
            </p>
            <p>
              Changes to this Notice Regarding Consent to Use Electronic
              Signatures and Records, including changes to hardware or software
              requirements
            </p>
            <p>
              We will notify you if there are changes to the terms of your
              eConsent or to the hardware or software requirements that could
              materially affect your eConsent or your ability to access or
              retain your Documents. The notice may require you to reaffirm your
              eConsent and your ability to access and retain electronic
              Documents, or may indicate that you can reaffirm your eConsent by
              continuing to access your Documents electronically. The notice
              will remind you of your right to withdraw your eConsent, and how
              to do so if you choose that option.
            </p>
            <p>Obligation regarding change of Access Device</p>
            <p>
              <strong>
                If you change your Access Device at any point during your use of
                this Platform or anytime during our relationship with you, it is
                your responsibility to ensure that the new Access Device meets
                the hardware and software requirements listed above and that you
                are still able to access and retain Documents using the new
                Access Device. By continuing to access your Documents
                electronically, you reaffirm your eConsent.
              </strong>
            </p>
            <p>
              By providing your consent, you indicate your agreement to and
              confirmation of the following:
            </p>
            <ul>
              <li>
                <p>I am able to access these terms regarding eConsent.</p>
              </li>
              <li>
                <p>
                  I consent to sign and receive Documents electronically in
                  place of paper documents. My electronic signature has the same
                  effect as if I were to sign in ink.
                </p>
              </li>
              <li>
                <p>
                  I have reviewed the hardware and software requirements, and
                  agree that my Access Device(s) meet these requirements. I
                  understand that it is my responsibility to ensure that any
                  Access Device(s) I use in the future meet these requirements.
                </p>
              </li>
              <li>
                <p>
                  I have provided my own valid and current email address, where
                  I can receive email, access hyperlinks, and access electronic
                  Documents. I will ensure that my financial institution and its
                  affiliates as well as CANDID and its affiliates always have a
                  valid and current email address for contacting me.
                </p>
              </li>
              <li>
                <p>
                  My eConsent applies to all of my activities in connection with
                  my use of this Platform, in other portals, and/or through
                  other methods we may use for delivery of electronic Documents.
                </p>
              </li>
            </ul>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ConsentToUseModal",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUser"]),

    loCompany() {
      return this.getUser.LOCompany.replace("-", " ");
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>