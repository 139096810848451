<template>
  <div>
    <div class="form-group">
      <div class="question">
        <!-- Question Text -->
        <h2 v-if="queIdExistsInQueText">{{ getQueWithName }}</h2>
        <h2 v-else>{{ question.questionText }}</h2>

        <!-- Question Description -->
        <!-- <p class="mt-3" v-if="queIsTermsOfUse">
          <a
            data-bs-toggle="modal"
            data-bs-target="#termsOfUseModal"
            class="text-underline"
            >Terms of Use</a
          >,&nbsp;
          <a
            data-bs-toggle="modal"
            data-bs-target="#privacyPolicyModal"
            class="text-underline"
            >Privacy Policy</a
          >,&nbsp;
          <a
            data-bs-toggle="modal"
            data-bs-target="#consentToUseRecordsModal"
            class="text-underline"
            >Consent to Use Electronic Signatures & Records</a
          >,&nbsp;
          <a
            data-bs-toggle="modal"
            data-bs-target="#consentToContactModal"
            class="text-underline"
            >Consent to Contact</a
          >
        </p> -->
        <p class="mt-3" v-if="!queIsTermsOfUse && question.questionDescription">
          {{ question.questionDescription }}
        </p>

        <!-- Question Sub Group Name -->
        <p class="personal-info mt-3" v-show="question.subGroupName">
          <span v-if="queIdExistsInSubGroupName">{{
            getSubGroupNameWithName
          }}</span>
          <span v-else>{{ question.subGroupName }}</span>
        </p>
      </div>

      <p v-if="showCoApplicant" class="personal-info mt-4 mb-4">
        Choose an answer for {{ getApplicantName }}
      </p>
      <div class="answer" v-if="question.questionOptions.length">
        <ul>
          <li v-for="(option, index) in question.questionOptions" :key="index">
            <input
              type="radio"
              :id="option.optionValue"
              :value="option.optionValue === 'Yes' ? true : false"
              v-model.trim="checkBoxAnswer"
            />
            <label :for="option.optionValue">
              <img
                v-if="option.optionSvgLink"
                class="dynamic-color-icons"
                :src="option.optionSvgLink"
              />

              <span>{{ option.optionName }}</span>
            </label>
          </li>

          <div v-if="queIsTermsOfUse" class="checkbox custom-checkbox">
            <input
              type="checkbox"
              id="applicant_checkbox"
              v-model="applicantTicked"
            />
            <label for="applicant_checkbox" role="button"
              ><span
                >I've read and agree to
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#termsOfUseModal"
                  class="text-underline"
                  >Terms of Use</a
                >,
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#privacyPolicyModal"
                  class="text-underline"
                  >Privacy Policy</a
                >,
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#consentToUseRecordsModal"
                  class="text-underline"
                  >Consent to Use Electronic Signatures & Records</a
                >, and
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#consentToContactModal"
                  class="text-underline"
                  >Consent to Contact</a
                >. <strong>(Borrower: {{ getApplicantName }})</strong></span
              ></label
            >
          </div>
        </ul>
      </div>

      <p v-if="showCoApplicant" class="personal-info mt-4 mb-4">
        Choose an answer for {{ getCoApplicantName }}
      </p>
      <div v-if="showCoApplicant" class="answer">
        <ul>
          <li v-for="(option, index) in question.questionOptions" :key="index">
            <input
              type="radio"
              :id="'co' + option.optionValue"
              :value="option.optionValue === 'Yes' ? true : false"
              v-model.trim="coCheckBoxAnswer"
            />
            <label :for="'co' + option.optionValue">
              <img
                v-if="option.optionSvgLink"
                class="dynamic-color-icons"
                :src="option.optionSvgLink"
              />

              <span>{{ option.optionName }}</span>
            </label>
          </li>

          <div v-if="queIsTermsOfUse" class="checkbox custom-checkbox">
            <input
              type="checkbox"
              id="co_applicant_checkbox"
              v-model="coApplicantTicked"
            />
            <label for="co_applicant_checkbox" role="button"
              ><span
                >I've read and agree to
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#termsOfUseModal"
                  class="text-underline"
                  >Terms of Use</a
                >,
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#privacyPolicyModal"
                  class="text-underline"
                  >Privacy Policy</a
                >,
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#consentToUseRecordsModal"
                  class="text-underline"
                  >Consent to Use Electronic Signatures & Records</a
                >, and
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#consentToContactModal"
                  class="text-underline"
                  >Consent to Contact</a
                >. <strong>(Borrower: {{ getCoApplicantName }})</strong></span
              ></label
            >
          </div>
        </ul>
      </div>
    </div>

    <div class="button mt-4 justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-outline" @click="previousQuestion">
          <span>Previous</span>
        </button>
        <button
          type="button"
          class="btn"
          :class="!disableButton ? 'btn-red' : ''"
          :disabled="disableButton"
          @click="saveTheData"
        >
          <span>Continue</span>
        </button>
      </div>

      <div class="align-text-right col-sm-6">
        <a
          v-if="question.questionLink && !queIsTermsOfUse"
          :data-bs-toggle="
            question.questionLink && question.questionLinkDescription
              ? 'modal'
              : ''
          "
          data-bs-target="#infoModal"
          class="text-underline"
          >{{ question.questionLink }}</a
        >
      </div>
      <info-modal
        v-if="
          question.questionLink &&
          question.questionLinkDescription &&
          !queIsTermsOfUse
        "
        :title="question.questionLink"
        :description="question.questionLinkDescription"
      />

      <terms-of-use :id="`termsOfUseModal`" />
      <privacy-policy :id="`privacyPolicyModal`" />
      <consent-to-use-records :id="`consentToUseRecordsModal`" />
      <consent-to-contact :id="`consentToContactModal`" />
    </div>
  </div>
</template>

<script>
import commonFunctions from "./../../mixins/commonfunctions";
import InfoModal from "../InfoModal.vue";
import TermsOfUse from "../modals/TermsOfUse.vue";
import PrivacyPolicy from "../modals/PrivacyPolicy.vue";
import ConsentToUseRecords from "../modals/ConsentToUseRecords.vue";
import ConsentToContact from "../modals/ConsentToContact.vue";

export default {
  name: "CheckBox",
  mixins: [commonFunctions],
  components: {
    InfoModal,
    TermsOfUse,
    PrivacyPolicy,
    ConsentToUseRecords,
    ConsentToContact,
  },
  data() {
    return {
      checkBoxAnswer: "",
      coCheckBoxAnswer: "",
      queIsTermsOfUse: false,
      applicantTicked: false,
      coApplicantTicked: false,
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
  },
  computed: {
    disableButton() {
      if (this.queIsTermsOfUse) {
        if (
          this.checkBoxAnswer !== true ||
          this.applicantTicked !== true ||
          (this.showCoApplicant &&
            (this.coCheckBoxAnswer !== true || this.coApplicantTicked !== true))
        )
          return true;
        else return false;
      }

      if (this.showCoApplicant) {
        if (
          this.checkBoxAnswer === "" ||
          this.checkBoxAnswer === null ||
          this.cocheckBoxAnswer === "" ||
          this.cocheckBoxAnswer === null
        )
          return true;
      } else {
        if (this.checkBoxAnswer === "" || this.checkBoxAnswer === null)
          return true;
      }

      return false;
    },

    showCoApplicant() {
      let que = this.question;

      if (que.questionCoFieldMapping && que.coDependentQuestionId) {
        let checkIt = this.getQuestions.some(
          (v) =>
            v.questionId === que.coDependentQuestionId &&
            v.questionAnswer === que.coDependentQuestionAnswer
        );

        if (checkIt) return true;
        if (!checkIt) return false;
      }

      return false;
    },
  },
  mounted() {
    this.checkTheDependancy(this.question);
    this.checkIfQueTermsOfUse();

    let default_answer =
      this.question.defaultQuestionAnswer === "Yes" ? true : false;

    if (!this.getIsAuthenticated) {
      this.checkBoxAnswer = this.question.questionAnswer || default_answer;
      this.coCheckBoxAnswer = this.question.coQuestionAnswer || default_answer;
    }

    if (this.getIsAuthenticated) {
      this.checkBoxAnswer = this.question.questionAnswer
        ? this.question.questionAnswer === "false"
          ? false
          : this.question.questionAnswer === "true"
          ? true
          : default_answer
        : default_answer;

      this.coCheckBoxAnswer = this.question.coQuestionAnswer
        ? this.question.coQuestionAnswer === "false"
          ? false
          : this.question.coQuestionAnswer === "true"
          ? true
          : default_answer
        : default_answer;
    }
  },
  methods: {
    async saveTheData() {
      this.fetchCompLoaderStatus(true);

      let payLoad = {};

      payLoad = {
        question_id: this.question.questionId,
        answer: this.checkBoxAnswer,
        co_answer: this.coCheckBoxAnswer || null,
      };

      this.addAnswerLocally(payLoad);

      if (this.getUserId && this.question.apiName === "LockLearProspectAuth") {
        let theQuestion = this.question;
        theQuestion.questionAnswer = this.checkBoxAnswer;
        theQuestion.coQuestionAnswer = this.coCheckBoxAnswer;

        // payLoad for API
        payLoad = {
          prospectId: this.getUserId,
          questionsToUpdate: [theQuestion],
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/question/prospect/Questions/", payLoad)
          .then((response) => {
            let resp = response.data;

            if (resp.response === "Success") {
              this.updateQueObjectWithAnswer(resp.appQuestions);
              this.updateDependantAnswers();
              this.nextQuestion();
            }

            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      if (!this.getUserId || this.question.apiName === null) {
        this.updateDependantAnswers();
        this.nextQuestion();
      }

      this.fetchCompLoaderStatus(false);
    },

    nextQuestion() {
      if (
        this.getActiveQuestion + 1 > this.getQuestions.length &&
        !this.getEditModeFlag
      )
        this.$router.push("/submit-application");

      if (this.getActiveQuestion <= this.getQuestions.length)
        this.fetchActiveQuestion(this.getActiveQuestion + 1);
    },

    checkIfQueTermsOfUse() {
      if (this.question.questionLink) {
        let splitLinks = this.question.questionLink.split("&&");

        let theArr = [
          "Terms of Use",
          "Privacy Policy",
          "Consent to Use Electronic Signatures & Records",
          "Consent to Contact",
        ];

        this.queIsTermsOfUse =
          splitLinks.length === theArr.length &&
          splitLinks.every((value, index) => value === theArr[index]);
      }
    },

    updateDependantAnswers() {
      let que = this.question;

      let check = this.getQuestions.some(
        (h) =>
          h.dependentQuestionId === que.questionId ||
          h.coDependentQuestionId === que.questionId
      );

      if (check) {
        this.getQuestions.forEach((k) => {
          if (
            k.dependentQuestionId === que.questionId &&
            this.checkBoxAnswer === false
          ) {
            k.questionAnswer = null;
          }

          if (
            k.coDependentQuestionId === que.questionId &&
            this.checkBoxAnswer === false
          ) {
            k.coQuestionAnswer = null;
          }
        });

        let dependantQues = this.getQuestions.filter(
          (v) => v.dependentQuestionId === que.questionId
        );

        dependantQues.forEach((t) => {
          this.getQuestions.forEach((x) => {
            if (
              x.dependentQuestionId === t.questionId &&
              t.questionAnswer === null
            ) {
              x.questionAnswer = null;
            }

            if (
              x.coDependentQuestionId === t.questionId &&
              t.coQuestionAnswer === null
            ) {
              x.coQuestionAnswer = null;
            }
          });
        });
      }
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.getActiveQuestion - 1);
    },
  },
};
</script>

<style scoped>
.checkbox.custom-checkbox label:before {
  background-color: transparent;
  border: 1px solid #929598;
  top: 2px;
}
.checkbox.custom-checkbox input:checked + label:before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.checkbox.custom-checkbox input:checked + label:after {
  top: 5px;
}
</style>