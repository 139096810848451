<template>
  <div
    class="modal fade consentToContactModal"
    :id="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>Consent to Contact</h2>
          </div>
          <div class="content-desc">
            <!-- <p>
              By clicking "Continue”, you agree that
              <strong>{{ loCompany }}</strong> and its current and future
              affiliates, successors, and assignees may call/text you at the
              phone number(s) provided about <strong>{{ loCompany }}</strong
              >’s services and products, which may involve use of automated
              means and prerecorded/artificial voices. You do not need to
              consent as a condition of buying any property, goods or services.
              Message/data rates may apply.
            </p> -->
            <p>
              By submitting your phone number and clicking the "Submit" button,
              you are providing express written consent for
              {{ loCompany }} and its affiliates, agents, and service providers
              to contact you at that number regarding products or services,
              including via autodialed and/or prerecorded or artificial voice
              calls and text messages (SMS and MMS), or email even if your
              telephone number is a cellular number or on a corporate, state, or
              the National Do Not Call Registry (DNC) or other do not contact
              list. You may also reach us at
              <a href="tel:615.933.2080">615.933.2080</a>. You may opt-out at
              any time by emailing us a Do Not Call Request at
              <a href="info@candid.inc">info@candid.inc</a> and providing your
              phone number.
              <strong
                >Your consent is not required or a condition of any purchase.
                Message and data rates may apply.</strong
              >
              By communicating with us by phone you consent to calls being
              recorded and monitored.
            </p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ConsetToContactModal",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getCompanyName", "getUser"]),

    loCompany() {
      return this.getCompanyName.replaceAll("-", " ");
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>