<template>
  <div>
    <div class="form-group">
      <div class="question">
        <h2 v-if="getQueId">{{ getUserNameWithQueText }}</h2>
        <h2 v-else>{{ question.groupName }}</h2>
        <p class="mt-3" v-show="question.questionDescription">
          {{ question.questionDescription }}
        </p>
        <p class="personal-info mt-3" v-show="question.subGroupName">
          {{ question.subGroupName }}
        </p>
      </div>
      <div
        v-show="
          !addressId &&
          getActiveSection === 'Co-Applicant Info' &&
          !userAddresses.length
        "
        class="badge bg-dynamic mb-4 cursor-pointer"
        @click="copyApplicantsAddress"
      >
        Copy {{ getApplicantName }}'s address history
      </div>
      <div class="row">
        <template v-for="(que, index) in theQuestions">
          <transition :key="index" name="fade">
            <template v-if="checkLocalDependancy(que)">
              <div :class="que.questionCSSClass">
                <form @submit.prevent>
                  <div class="form-group">
                    <!-- Label for Input Types -->
                    <label :for="que.questionText" class="form-label"
                      >{{ que.questionText }}
                      <small v-if="que.isMandatory" class="asterisk-mark"
                        >*</small
                      >
                    </label>

                    <!-- Address field with Google Places API -->
                    <vue-google-autocomplete
                      v-if="index === 0"
                      class="form-control"
                      ref="address"
                      id="map"
                      v-on:placechanged="pullAddressFields"
                      v-on:inputChange="pullAddressFields"
                      :placeholder="que.questionText"
                      v-model="theAnswers[index]"
                      country="us"
                      :fields="[
                        'formatted_address',
                        'address_components',
                        'geometry',
                      ]"
                    >
                    </vue-google-autocomplete>

                    <div v-else>
                      <!-- Type: Picklist OR Checkbox -->
                      <template
                        v-if="
                          que.questionType === 'Picklist' ||
                          que.questionType === 'Checkbox'
                        "
                      >
                        <div class="answer">
                          <div class="d-flex">
                            <div
                              class="small-radio pe-3"
                              v-for="(option, i) in que.questionOptions"
                              :key="i"
                            >
                              <input
                                v-if="que.questionType === 'Picklist'"
                                type="radio"
                                :id="option.optionValue + que.questionId"
                                :value="option.optionValue"
                                v-model.trim="theAnswers[index]"
                              />
                              <input
                                v-if="que.questionType === 'Checkbox'"
                                type="radio"
                                :id="option.optionValue + que.questionId"
                                :value="
                                  option.optionValue === 'Yes' ? true : false
                                "
                                v-model.trim="theAnswers[index]"
                              />
                              <label :for="option.optionValue + que.questionId">
                                <img
                                  v-if="option.optionSvgLink"
                                  class="dynamic-color-icons"
                                  :src="option.optionSvgLink"
                                />
                                <span>{{ option.optionName }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- Type: Dropdown -->
                      <template v-else-if="que.questionType === 'Dropdown'">
                        <select
                          class="form-select"
                          v-model.trim="theAnswers[index]"
                        >
                          <option
                            v-for="(option, index) in que.questionOptions"
                            :key="index"
                          >
                            {{ option.optionName }}
                          </option>
                        </select>
                      </template>

                      <!-- Type: Input Fields -->
                      <template v-else>
                        <div
                          v-if="inputFieldsWithIcons.includes(que.questionType)"
                          class="input-group two-input-group"
                        >
                          <div class="input-group-prepend">
                            <span
                              class="input-group-text"
                              :id="'basic-addon' + index"
                              ><i :class="getIcons(que.questionType)"></i
                            ></span>
                          </div>
                          <input
                            :type="getFieldType(que)"
                            :inputmode="getInputMode(que)"
                            class="form-control"
                            :id="que.questionText"
                            :placeholder="que.questionText"
                            v-model="theAnswers[index]"
                          />
                          <div
                            class="input-group-append"
                            v-if="
                              getFieldType(que) === 'password' ||
                              getFieldType(que) === 'show-password'
                            "
                          >
                            <span
                              @click="showInput(index)"
                              class="input-group-text input-group-eye bg-transparent"
                              ><i
                                :class="
                                  getFieldType(que) === 'show-password'
                                    ? 'far fa-eye'
                                    : 'fas fa-eye-slash'
                                "
                              ></i
                            ></span>
                          </div>
                        </div>

                        <input
                          v-else
                          :type="getFieldType(que)"
                          :inputmode="getInputMode(que)"
                          class="form-control"
                          :id="que.questionText"
                          :placeholder="que.questionText"
                          v-model.trim="theAnswers[index]"
                          :maxlength="
                            que.questionFieldMapping === 'postalcode__c' ||
                            que.questionFieldMapping ===
                              'candidoem__postalcode__c'
                              ? 5
                              : ''
                          "
                          @input="
                            que.questionFieldMapping === 'state__c' ||
                            que.questionFieldMapping === 'candidoem__state__c'
                              ? checkStateCode(theAnswers[index])
                              : que.questionFieldMapping === 'postalcode__c' ||
                                que.questionFieldMapping ===
                                  'candidoem__postalcode__c'
                              ? checkZipCode(theAnswers[index], true)
                              : que.questionType === 'Email'
                              ? checkEmail(theAnswers[index])
                              : ''
                          "
                        />
                        <small
                          class="text-danger"
                          v-if="que.questionType === 'Email' && !validEmail"
                        >
                          Please enter a valid email address.</small
                        >
                        <small
                          class="text-danger"
                          v-if="
                            !validStateCode &&
                            (que.questionFieldMapping === 'state__c' ||
                              que.questionFieldMapping ===
                                'candidoem__state__c')
                          "
                        >
                          Please enter State abbreviation</small
                        >
                        <small
                          class="text-danger"
                          v-if="
                            !validZipCode &&
                            (que.questionFieldMapping === 'postalcode__c' ||
                              que.questionFieldMapping ===
                                'candidoem__postalcode__c')
                          "
                        >
                          Invalid Postal Code</small
                        >
                      </template>
                    </div>
                  </div>
                </form>
              </div>
            </template>
          </transition>
        </template>
      </div>
    </div>
    <div class="button">
      <button
        type="button"
        class="btn btn-outline"
        @click="userAddresses.length ? viewAddresses() : previousQuestion()"
      >
        <span>Previous</span>
      </button>
      <button
        type="button"
        class="btn"
        :class="checkTheValidation ? 'btn-red' : ''"
        :disabled="!checkTheValidation"
        @click="saveTheData"
      >
        <span>Continue</span>
      </button>
    </div>
  </div>
</template>

<script>
import { eventBus } from "./../../event-bus";
import commonFunctions from "./../../mixins/commonfunctions";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

export default {
  name: "AddressFields",
  mixins: [commonFunctions],
  components: { VueGoogleAutocomplete },
  data() {
    return {
      theQuestions: [],
      theAnswers: [],
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
    addressId: {
      type: String,
    },
    userAddresses: {
      type: Array,
      required: true,
    },
  },
  computed: {
    checkTheValidation() {
      let theArr = [];
      let ques = this.theQuestions;
      let answers = this.theAnswers;

      for (let i = 0; i < ques.length; i++) {
        if (this.checkLocalDependancy(ques[i])) {
          if (
            ques[i].isMandatory &&
            (answers[i] === undefined ||
              answers[i] === "" ||
              answers[i] === null)
          )
            theArr.push(true);
          else if (
            ques[i].questionType === "Date" &&
            answers[i] === "Invalid date"
          )
            theArr.push(true);
          else theArr.push(false);
        }
      }

      return (
        theArr.every((v) => v === false) &&
        this.validEmail &&
        this.validStateCode &&
        this.validZipCode
      );
    },

    getQueId() {
      let checkCoApplicant = this.getQuestions.some(
        (v) =>
          (v.questionFieldMapping === "is_joint_applicant_added__c" ||
            v.questionFieldMapping ===
              "candidoem__is_joint_applicant_added__c") &&
          v.questionAnswer === "Yes"
      );

      if (checkCoApplicant) {
        let string = this.question.groupName;
        let question_id;

        if (string) {
          question_id = string.substring(
            string.indexOf("{") + 1,
            string.lastIndexOf("}")
          );
        }

        return question_id || false;
      }

      return false;
    },

    getUserNameWithQueText() {
      if (this.getQueId) {
        let string = this.question.groupName;
        let question_id = string.substring(
          string.indexOf("{"),
          string.lastIndexOf("}") + 1
        );

        let get_name = this.getQuestions.find(
          (v) => v.questionId === this.getQueId
        );

        let que_text = get_name
          ? string.replace(question_id, get_name.questionAnswer)
          : string;

        return que_text || "";
      }

      return "";
    },

    currentResidenceIndex() {
      return this.userAddresses.findIndex(
        (x) => x.addressId === this.addressId
      );
    },

    editModeAddress() {
      return (
        this.userAddresses.find((x) => x.addressId === this.addressId) || null
      );
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
  },
  beforeMount() {
    this.mergeTheQuestions();
  },
  mounted() {
    this.checkTheDependancy(this.question);
    this.prePopulateTheAnswers();
  },
  methods: {
    checkLocalDependancy(que) {
      const wish_to_refinance_map_1 =
        "is_this_the_home_you_wish_to_refinance__c";
      const wish_to_refinance_map_2 =
        "candidoem__is_this_the_home_you_wish_to_refinance__c";

      if (
        this.getAppFlow === "Refinance" &&
        (que.questionFieldMapping === wish_to_refinance_map_1 ||
          que.questionFieldMapping === wish_to_refinance_map_2)
      ) {
        // this IF block checks a specific question in the Refinance flow
        let check = this.getAllAddresses.some((o) => {
          return o.addressDetails.some((v) => {
            return v.addressData.find(
              (x) =>
                (x.questionFieldMapping === wish_to_refinance_map_1 ||
                  x.questionFieldMapping === wish_to_refinance_map_2) &&
                x.questionAnswer === "Yes"
            );
          });
        });

        if (check) {
          if (!this.editModeAddress) return false;

          if (this.editModeAddress) {
            let review = this.editModeAddress.addressData.find(
              (x) =>
                x.questionAnswer === "Yes" &&
                (x.questionFieldMapping === wish_to_refinance_map_1 ||
                  x.questionFieldMapping === wish_to_refinance_map_2)
            );

            if (!review) return false;
          }
        }
      }

      // this Code block checks the dependency of the questions
      if (que.dependentQuestionId) {
        let checkLocal = this.getQuestions.find(
          (m) => m.questionId === que.dependentQuestionId
        );

        if (checkLocal) {
          if (checkLocal.groupName === que.groupName) {
            let getTheIndex = this.theQuestions.findIndex(
              (v) => v.questionId === que.dependentQuestionId
            );

            if (
              getTheIndex > -1 &&
              que.dependentQuestionAnswer === this.theAnswers[getTheIndex]
            ) {
              if (
                que.questionFieldMapping === "monthly_rent__c" ||
                que.questionFieldMapping === "candidoem__monthly_rent__c"
              ) {
                if (!this.currentResidenceIndex || !this.userAddresses.length)
                  return true;
                else return false;
              }

              return true;
            } else {
              let getQueIndex = this.theQuestions.findIndex(
                (v) => v.questionId === que.questionId
              );

              if (getQueIndex) this.theAnswers[getQueIndex] = "";
              return false;
            }
          } else {
            if (checkLocal.questionAnswer === que.dependentQuestionAnswer)
              return true;
            else return false;
          }
        }
      } else {
        return true;
      }
    },

    showInput(index) {
      let queType = this.theQuestions[index].questionType;
      this.theQuestions[index].questionType =
        queType === "Password" ? "Show-Password" : "Password";
    },

    viewAddresses() {
      eventBus.$emit("show-fields", false);
    },

    async saveTheData() {
      this.fetchCompLoaderStatus(true);

      this.theQuestions.forEach((v, i) => {
        v.questionAnswer = this.theAnswers[i] || null;
      });

      // API for address
      if (this.getUserId) {
        // payLoad for API
        let payLoad = {
          prospectId: this.getUserId,
          flowName: this.getAppFlow,
          addressDetails: [
            {
              addressData: this.theQuestions,
              addressId: this.addressId || null,
            },
          ],
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/address/createAndUpdateAddress/", payLoad)
          .then(async (response) => {
            let resp = response.data;

            if (resp.response === "Success") await this.getAddresses();
            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      this.nextQuestion();
    },

    nextQuestion() {
      eventBus.$emit("show-fields", false);
      this.fetchCompLoaderStatus(false);
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.theQuestions[0].questionIndex - 1);
    },

    async copyApplicantsAddress() {
      let addresses = cloneDeep(this.getAllAddresses);

      if (addresses.length) {
        this.fetchLoaderStatus(true);

        let new_addresses;

        let pi_addresses = addresses.find(
          (k) => k.sectionName === "Personal Information"
        );

        if (pi_addresses) {
          new_addresses = pi_addresses.addressDetails.map((v) => {
            v.addressId = null;

            v.addressData.forEach((x) => {
              x.sectionName = "Co-Applicant Info";

              if (
                x.questionFieldMapping === "move_in_date__c" ||
                x.questionFieldMapping === "candidoem__move_in_date__c"
              ) {
                x.questionAnswer = moment(x.questionAnswer).format(
                  "YYYY-MM-DD"
                );
              }

              if (
                this.getAppFlow === "Refinance" &&
                (x.questionFieldMapping ===
                  "is_this_the_home_you_wish_to_refinance__c" ||
                  x.questionFieldMapping ===
                    "candidoem__is_this_the_home_you_wish_to_refinance__c")
              ) {
                x.questionAnswer = "No";
              }
            });

            return v;
          });
        }

        let payLoad = {
          prospectId: this.getUserId,
          flowName: this.getAppFlow,
          addressDetails: new_addresses,
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/address/createAndUpdateAddress/", payLoad)
          .then(async (response) => {
            let resp = response.data;

            if (resp.response === "Success") await this.getAddresses();
            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => console.log(error));

        eventBus.$emit("show-fields", false);
        this.fetchLoaderStatus(false);
      } else {
        this.info("No addresses to copy");
      }
    },

    mergeTheQuestions() {
      let theFields = [];

      for (
        let i = this.getActiveQuestion - 1;
        i < this.getQuestions.length;
        i++
      ) {
        if (
          this.getQuestions[i].groupName === "" ||
          this.getQuestions[i].groupName !== this.question.groupName
        ) {
          break;
        }

        theFields.push(this.getQuestions[i]);
      }

      this.theQuestions = theFields;
    },

    prePopulateTheAnswers() {
      // If logged in: Pre-populate user stored answers
      if (this.getIsAuthenticated && this.addressId) {
        let getAddr;

        getAddr = this.userAddresses.find(
          (v) => v.addressId === this.addressId
        );

        if (getAddr) {
          for (let i = 0; i < this.theQuestions.length; i++) {
            for (let j = 0; j < getAddr.addressData.length; j++) {
              if (
                this.theQuestions[i].questionId ===
                getAddr.addressData[j].questionId
              ) {
                let s = getAddr.addressData[j];

                let answer;

                if (s.questionType === "Date") {
                  answer = s.questionAnswer
                    ? moment(s.questionAnswer).format("YYYY-MM-DD")
                    : s.defaultQuestionAnswer
                    ? moment(s.defaultQuestionAnswer).format("YYYY-MM-DD")
                    : undefined;
                } else if (s.questionType === "Checkbox") {
                  answer =
                    s.questionAnswer === null || s.questionAnswer === ""
                      ? s.defaultQuestionAnswer
                        ? s.defaultQuestionAnswer === "false"
                          ? false
                          : true
                        : undefined
                      : s.questionAnswer === "false"
                      ? false
                      : true;
                } else {
                  answer =
                    s.questionAnswer || s.defaultQuestionAnswer || undefined;
                }

                this.$set(this.theAnswers, i, answer);
              }
            }
          }
        }
      }
    },

    pullAddressFields(addressData) {
      if (addressData.newVal) {
        let street_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "street__c" ||
            v.questionFieldMapping === "candidoem__street__c"
        );

        let fullStreet = addressData.newVal.split(",")[0];

        this.$set(this.theAnswers, street_index, fullStreet);
        this.$refs.address[0].$refs.autocomplete.value = fullStreet;
      } else {
        let street_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "street__c" ||
            v.questionFieldMapping === "candidoem__street__c"
        );

        let streetNumber = addressData.street_number;
        let streetName = addressData.route;

        let fullStreet;

        if (streetNumber && streetName)
          fullStreet = `${streetNumber} ${streetName}`;
        else {
          if (streetNumber) fullStreet = `${streetNumber}`;
          if (streetName) fullStreet = `${streetName}`;
        }

        this.$set(this.theAnswers, street_index, fullStreet);
        this.$refs.address[0].$refs.autocomplete.value = fullStreet;

        let city_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "city__c" ||
            v.questionFieldMapping === "candidoem__city__c"
        );

        let state_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "state__c" ||
            v.questionFieldMapping === "candidoem__state__c"
        );

        let zipcode_index = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "postalcode__c" ||
            v.questionFieldMapping === "candidoem__postalcode__c"
        );

        let city = addressData.locality;
        let state = addressData.administrative_area_level_1;
        let zipcode = addressData.postal_code;
        let latitude = addressData.latitude;
        let longitude = addressData.longitude;

        if (city) {
          this.$set(this.theAnswers, city_index, city);
        } else {
          this.$set(this.theAnswers, city_index, undefined);
        }

        if (state) {
          this.$set(this.theAnswers, state_index, state);
          this.validStateCode = true;
        } else {
          this.$set(this.theAnswers, state_index, undefined);
        }

        if (zipcode) {
          this.checkZipCode(zipcode, false);
          this.$set(this.theAnswers, zipcode_index, zipcode);
        } else {
          if (latitude && longitude) {
            this.$http
              .get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&result_type=postal_code&key=AIzaSyDYYKos_zgje0clujI_sKRXYsPrDo1k2FY`
              )
              .then((response) => {
                let resp = response.data;

                if (resp.status === "OK") {
                  let getZip = resp.results[0].address_components.find(
                    (el) => el.types[0] === "postal_code"
                  );

                  if (getZip) {
                    this.checkZipCode(getZip.long_name, false);
                    this.$set(this.theAnswers, zipcode_index, getZip.long_name);
                    return;
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

          this.$set(this.theAnswers, zipcode_index, undefined);
        }
      }
    },

    async getAddresses() {
      await this.$http
        .get(
          "/address/GetAllAddress/" +
            this.getUserId +
            "?flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchAllAddresses(resp.addressData || []);

            if (this.getAppFlow === "Refinance") {
              let is_subject_property = resp.addressData.some(
                (c) => c.hasSubjectProperty
              );

              this.fetchSubjectPropertyStatus(is_subject_property);
            }

            if (this.getAppFlow === "Buying" && this.getSubjectPropertyStatus)
              this.fetchSubjectPropertyStatus(false);
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
