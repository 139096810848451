<template>
  <div>
    <employment-fields
      v-if="showEmpFields"
      :question="question"
      :currentaq="currentaq"
      :previousaq="previousaq"
      :employmentId="currentEmploymentId"
      :userEmployments="userEmployments"
      :additionalInfo="showPreviousEmployerButton"
      :currentEmployer="getCurrentEmployer()"
    />

    <form class="property" v-if="!showEmpFields">
      <div class="question">
        <h2 v-if="getQueId">{{ getUserNameWithQueText }}</h2>
        <h2 v-else>{{ question.groupName }}</h2>
        <p class="mt-3" v-show="question.questionDescription">
          {{ question.questionDescription }}
        </p>
        <p class="personal-info mt-3" v-show="question.subGroupName">
          {{ question.subGroupName }}
        </p>
      </div>
      <ul>
        <li v-for="(emp, index) in userEmployments" :key="index">
          <div class="title">
            <h2>{{ fetchTheTitle(emp) }}</h2>
            <span
              class="text-red"
              v-if="
                emp.detailType === 'Employer Information' &&
                index === getCurrentEmployer()
              "
              ><strong>Your current employer</strong></span
            >
          </div>
          <p>
            <span>
              <strong>{{
                formTheAddress(emp.employmentInfoData)
              }}</strong></span
            >
          </p>
          <p v-for="(subs, i) in emp.employmentInfoData" :key="i">
            <template
              v-if="
                checkLocalDependancy(subs, emp.employmentInfoData) &&
                subs.questionFieldMapping !== 'employer_name__c' &&
                subs.questionFieldMapping !== 'candidoem__employer_name__c' &&
                subs.questionFieldMapping !== 'name' &&
                subs.questionFieldMapping !== 'candidoem__name'
              "
            >
              <span v-if="subs.questionType === 'Text' && subs.questionAnswer">
                <template
                  v-if="
                    subs.questionFieldMapping !== 'street__c' &&
                    subs.questionFieldMapping !== 'candidoem__street__c' &&
                    subs.questionFieldMapping === 'apt_unit__c' &&
                    subs.questionFieldMapping === 'candidoem__apt_unit__c' &&
                    subs.questionFieldMapping !== 'city__c' &&
                    subs.questionFieldMapping !== 'candidoem__city__c' &&
                    subs.questionFieldMapping !== 'state__c' &&
                    subs.questionFieldMapping !== 'candidoem__state__c' &&
                    subs.questionFieldMapping !== 'postalcode__c' &&
                    subs.questionFieldMapping !== 'candidoem__postalcode__c'
                  "
                >
                  <span>{{ subs.questionText }}:</span>
                  <strong> {{ subs.questionAnswer }}</strong>
                </template>
                <!-- <template v-else
                  ><strong>
                    {{ formTheAddress2(subs.questionAnswer) }}</strong
                  ></template
                > -->
              </span>
              <span
                v-else-if="subs.questionType === 'Date' && subs.questionAnswer"
              >
                <template
                  v-if="
                    subs.questionFieldMapping === 'end_date__c' ||
                    subs.questionFieldMapping === 'candidoem__end_date__c'
                  "
                >
                  <template v-if="index !== getCurrentEmployer()">
                    {{ subs.questionText }}:
                    <strong>{{ subs.questionAnswer | formatDate }}</strong>
                  </template>
                </template>
                <template v-else>
                  {{ subs.questionText }}:
                  <strong>{{ subs.questionAnswer | formatDate }}</strong>
                </template>
              </span>
              <span
                v-else-if="subs.questionType === 'Phone' && subs.questionAnswer"
                >{{ subs.questionText }}:
                <strong> {{ subs.questionAnswer }}</strong></span
              >
            </template>
          </p>
          <div class="edit-delete mt-3">
            <a
              class="btn-icon edit cursor-pointer"
              data-bs-toggle="modal"
              :data-bs-target="`#deleteModal${emp.employmentInfoId}`"
            >
              <i class="fa fa-trash"></i>&nbsp;&nbsp; Delete</a
            >&nbsp;&nbsp;&nbsp;
            <a
              @click="
                showEmpFields = true;
                currentEmploymentId = emp.employmentInfoId;
              "
              class="btn-icon edit cursor-pointer"
            >
              <i class="fa fa-pencil-alt"></i>&nbsp;&nbsp; Edit
            </a>
          </div>

          <!----Delete Modal start---->
          <div
            class="modal fade"
            :id="`deleteModal${emp.employmentInfoId}`"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title text-red" id="staticBackdropLabel">
                    Warning!!!
                  </h3>
                </div>
                <div class="modal-body">
                  Are you sure you want to delete this record?
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    @click="deleteTheEmployment(emp)"
                    class="btn btn-red"
                    data-bs-dismiss="modal"
                  >
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!----Delete Modal end---->
        </li>

        <li>
          <div class="title">
            <button
              type="button"
              @click="
                showEmpFields = true;
                currentEmploymentId = '';
              "
              class="d-flex align-items-center"
            >
              <div class="plus-icon">
                <i class="fa fa-plus"></i>
              </div>
              <h2 class="mb-0">Add employer</h2>
            </button>
          </div>
        </li>
      </ul>

      <div class="button mt-4 pt-3">
        <button type="button" class="btn btn-outline" @click="previousQuestion">
          <span>Previous</span>
        </button>
        <button type="button" class="btn btn-red" @click="nextQuestion">
          <span>Continue</span>
        </button>
      </div>
      <a
        v-if="question.questionLink"
        :data-bs-toggle="
          question.questionLink && question.questionLinkDescription
            ? 'modal'
            : ''
        "
        data-bs-target="#infoModal"
        class="text-underline"
        >{{ question.questionLink }}</a
      >
      <info-modal
        v-if="question.questionLink && question.questionLinkDescription"
        :title="question.questionLink"
        :description="question.questionLinkDescription"
      />
    </form>
  </div>
</template>

<script>
import { eventBus } from "./../../event-bus";
import commonFunctions from "./../../mixins/commonfunctions";
import EmploymentFields from "./EmploymentFields.vue";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "View-Employment",
  components: { EmploymentFields },
  mixins: [commonFunctions],
  data() {
    return {
      showEmpFields: false,
      currentEmploymentId: "",
      theQuestions: [],
      userEmployments: [],
      checkIfEmploymentAdded: false,
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  watch: {
    getAllEmployment: {
      deep: true,
      immediate: true,
      handler(newVal) {
        let employments = newVal.find(
          (v) => v.sectionName === this.getActiveSection
        );

        if (employments)
          this.userEmployments = employments.employmentInfoDetails;
        else this.userEmployments = [];
      },
    },

    userEmployments: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.length) this.showEmpFields = false;
        if (!newVal.length) this.showEmpFields = true;

        this.checkIfEmploymentAdded = newVal.some(
          (v) => v.detailType === "Employer Information"
        );
      },
    },
  },
  computed: {
    getQueId() {
      let checkCoApplicant = this.getQuestions.some(
        (v) =>
          (v.questionFieldMapping === "is_joint_applicant_added__c" ||
            v.questionFieldMapping ===
              "candidoem__is_joint_applicant_added__c") &&
          v.questionAnswer === "Yes"
      );

      if (checkCoApplicant) {
        let string = this.question.groupName;
        let question_id;

        if (string) {
          question_id = string.substring(
            string.indexOf("{") + 1,
            string.lastIndexOf("}")
          );
        }

        return question_id || false;
      }

      return false;
    },

    getUserNameWithQueText() {
      if (this.getQueId) {
        let string = this.question.groupName;
        let question_id = string.substring(
          string.indexOf("{"),
          string.lastIndexOf("}") + 1
        );

        let get_name = this.getQuestions.find(
          (v) => v.questionId === this.getQueId
        );

        let que_text = get_name
          ? string.replace(question_id, get_name.questionAnswer)
          : string;

        return que_text || "";
      }

      return "";
    },

    showPreviousEmployerButton() {
      if (
        this.userEmployments.length &&
        (this.getActiveSection === "Annual Income" ||
          this.getActiveSection === "Co-Applicant Annual Income")
      ) {
        let compareDate = moment().subtract(2, "years").format("M/D/YYYY");

        let check = this.userEmployments.some((v) => {
          let get_date = v.employmentInfoData.find(
            (x) =>
              x.questionFieldMapping === "start_date__c" ||
              x.questionFieldMapping === "candidoem__start_date__c"
          );

          if (get_date?.questionAnswer)
            return moment(get_date.questionAnswer).isBefore(compareDate);
        });

        return !check || !this.checkIfEmploymentAdded;
      }

      return false;
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
    this.mergeTheQuestions();

    eventBus.$on("show-emp-fields", (data) => {
      this.showEmpFields = data;
    });
  },
  mounted() {
    this.checkTheDependancy(this.question);

    let employments = this.getAllEmployment.find(
      (v) => v.sectionName === this.getActiveSection
    );

    if (employments) this.userEmployments = employments.employmentInfoDetails;

    if (!this.userEmployments.length) this.showEmpFields = true;
  },
  methods: {
    fetchTheTitle(data) {
      let que;

      if (data.detailType === "Employer Information") {
        que = data.employmentInfoData.find(
          (g) =>
            g.questionFieldMapping === "employer_name__c" ||
            g.questionFieldMapping === "candidoem__employer_name__c"
        );
      }

      if (data.detailType === "Academic Information") {
        que = data.employmentInfoData.find(
          (g) =>
            g.questionFieldMapping === "name" ||
            g.questionFieldMapping === "candidoem__name"
        );
      }

      return que?.questionAnswer ? que.questionAnswer : "";
    },

    formTheAddress(address) {
      let addr = cloneDeep(address);

      let x = "";

      addr.forEach((v) => {
        if (
          v.questionFieldMapping === "street__c" ||
          v.questionFieldMapping === "candidoem__street__c" ||
          v.questionFieldMapping === "apt_unit__c" ||
          v.questionFieldMapping === "candidoem__apt_unit__c" ||
          v.questionFieldMapping === "city__c" ||
          v.questionFieldMapping === "candidoem__city__c" ||
          v.questionFieldMapping === "state__c" ||
          v.questionFieldMapping === "candidoem__state__c" ||
          v.questionFieldMapping === "postalcode__c" ||
          v.questionFieldMapping === "candidoem__postalcode__c"
        ) {
          if (v.questionAnswer) {
            x += ", " + v.questionAnswer;
          }
        }
      });

      return x.substring(1);
    },

    // formTheAddress2(value) {
    //   if (value.lastIndexOf("#") === value.length - 1) {
    //     value = value.replaceAll("#", "").trim().slice(0, -1);
    //   }

    //   return value;
    // },

    checkLocalDependancy(que, que_array) {
      if (que.dependentQuestionId) {
        let checkLocal = this.getQuestions.find(
          (m) => m.questionId === que.dependentQuestionId
        );

        if (checkLocal) {
          if (checkLocal.groupName === que.groupName) {
            let getTheIndex = this.theQuestions.findIndex(
              (v) => v.questionId === que.dependentQuestionId
            );

            if (
              getTheIndex > -1 &&
              que.dependentQuestionAnswer ===
                que_array[getTheIndex].questionAnswer
            ) {
              return true;
            }

            return false;
          }
        }
      }

      return true;
    },

    getCurrentEmployer() {
      let current_date = moment().format("M/D/YYYY");

      let arrOfDays = this.userEmployments.map((v) => {
        if (v.detailType === "Employer Information") {
          let date = v.employmentInfoData.find(
            (y) =>
              y.questionFieldMapping === "start_date__c" ||
              y.questionFieldMapping === "candidoem__start_date__c"
          );

          if (date?.questionAnswer)
            return moment(current_date).diff(
              moment(date.questionAnswer),
              "days"
            );
        }
      });

      // Remove the negative numbers as they future dates
      let newArrOfDays = arrOfDays.filter((x) => {
        return x > -1;
      });

      // Get the smallest positive number from the array
      let the_number = Math.min(...newArrOfDays);

      // returns index of the nearest start date which is before the start date
      return arrOfDays.indexOf(the_number);
    },

    async deleteTheEmployment(emp) {
      this.fetchLoaderStatus(true);

      await this.$http
        .get(
          "/employmentInfo/deleteEmploymentInfo?employmentInfoId=" +
            emp.employmentInfoId +
            "&flowName=" +
            this.getAppFlow +
            "&prospectId=" +
            this.getUserId +
            "&sectionName=" +
            this.getActiveSection +
            "&orgId=" +
            this.getOrgId
        )
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.currentEmploymentId = "";
            await this.getEmploymentInfo();
            this.success("Employment record deleted successfully");
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchLoaderStatus(false);
    },

    mergeTheQuestions() {
      let theFields = [];

      for (
        let i = this.getActiveQuestion - 1;
        i < this.getQuestions.length;
        i++
      ) {
        if (
          this.getQuestions[i].groupName === "" ||
          this.getQuestions[i].groupName !== this.question.groupName
        ) {
          break;
        }
        theFields.push(this.getQuestions[i]);
      }

      this.theQuestions = theFields;
    },

    nextQuestion() {
      if (!this.skipTheValidation) {
        if (!this.checkIfEmploymentAdded) {
          this.info("Please provide at least one employment details.");
          return;
        }

        if (
          (this.getActiveSection === "Annual Income" ||
            this.getActiveSection === "Co-Applicant Annual Income") &&
          this.showPreviousEmployerButton
        ) {
          this.info(
            "Please provide minimum required 2 years of employment or education history."
          );
          return;
        }
      }

      if (
        this.getActiveQuestion + 1 > this.getQuestions.length &&
        !this.getEditModeFlag
      )
        this.$router.push("/submit-application");

      if (this.getActiveQuestion <= this.getQuestions.length) {
        this.fetchActiveQuestion(this.theQuestions.at(-1).questionIndex + 1);
      }
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.theQuestions[0].questionIndex - 1);
    },

    async getEmploymentInfo() {
      await this.$http
        .get(
          "/employmentInfo/GetAllEmploymentInfo/" +
            this.getUserId +
            "?flowName=" +
            this.getAppFlow +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchAllEmployment(resp.employmentInfoData || []);
          }

          if (resp.response === "Error") {
            console.log(resp.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
