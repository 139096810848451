<template>
  <div class="form-element">
    <div class="sign-in-up forgot-password" id="forgot-password">
      <div class="overlay"></div>
      <div class="form">
        <div class="form__content">
          <div class="lock-icon">
            <span>
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.81592 2.25974C4.12462 3.48872 4 4.95088 4 6H3C1.34315 6 0 7.34315 0 9V17C0 18.6569 1.34315 20 3 20H15C16.6569 20 18 18.6569 18 17V9C18 7.34315 16.6569 6 15 6L14 5.99998C14 4.95087 13.8754 3.48871 13.1841 2.25973C12.829 1.62845 12.3194 1.05012 11.6031 0.63486C10.8875 0.220048 10.021 0 9 0C7.97899 0 7.11246 0.220048 6.39691 0.63486C5.68058 1.05012 5.17102 1.62845 4.81592 2.25974ZM6.55908 3.24026C6.12538 4.01128 6 5.04912 6 6H12C12 5.04911 11.8746 4.01129 11.4409 3.24027C11.2335 2.87155 10.9618 2.57488 10.6 2.36514C10.2375 2.15495 9.72899 2 9 2C8.27101 2 7.76254 2.15495 7.39997 2.36514C7.03817 2.57488 6.76648 2.87155 6.55908 3.24026ZM11 12C11 12.7403 10.5978 13.3866 10 13.7324V15C10 15.5523 9.55228 16 9 16C8.44772 16 8 15.5523 8 15V13.7324C7.4022 13.3866 7 12.7403 7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12Z"
                  :fill="getAssets.primaryBrandColor || '#BE2530'"
                />
              </svg>
            </span>
          </div>
          <h2>Can't log in?</h2>

          <p>Restore access to your account</p>
          <div class="d-flex justify-content-around">
            <p
              class="d-flex align-items-center text-secondary px-4 cursor-pointer"
              @click="changeMethod('email')"
            >
              <img
                src="../assets/images/email.png"
                height="25"
                alt="Email icon"
              />&nbsp;Receive an email
            </p>

            <p
              class="d-flex align-items-center text-secondary px-4 cursor-pointer"
              @click="changeMethod('message')"
            >
              <img
                src="../assets/images/chat.png"
                height="25"
                alt="Text icon"
              />&nbsp;Receive a text
            </p>
          </div>
          <form @submit.prevent="sendOtp">
            <div class="form-group text-left">
              <label class="form-label">We'll send a code to</label>
              <template v-if="sendCodeMethod === 'email'">
                <input
                  v-model.trim="$v.email.$model"
                  type="email"
                  inputmode="email"
                  :class="$v.email.$error ? 'is-invalid' : ''"
                  class="form-control"
                  placeholder="Email"
                />
                <small class="invalid-feedback" v-if="!$v.email.required"
                  >Email field is required.</small
                >
                <small class="invalid-feedback" v-else-if="!$v.email.email"
                  >Please enter a valid email address.</small
                >
              </template>
              <template v-if="sendCodeMethod === 'message'">
                <input
                  v-model.trim="$v.phone.$model"
                  type="text"
                  inputmode="tel"
                  maxlength="10"
                  :class="$v.phone.$error ? 'is-invalid' : ''"
                  class="form-control"
                  placeholder="Mobile Number"
                />
                <small class="invalid-feedback" v-if="!$v.phone.required"
                  >Mobile Number field is required.</small
                >
                <small class="invalid-feedback" v-else-if="!$v.phone.numeric"
                  >Mobile Number field accepts only numbers.</small
                >
                <small class="invalid-feedback" v-else-if="!$v.phone.minLength"
                  >Please enter {{ $v.phone.$params.minLength.min }} digits
                  mobile number.</small
                >
              </template>
            </div>
            <div class="button w-100">
              <button
                type="button"
                class="btn w-100"
                :class="!$v.$invalid ? 'btn-red' : ''"
                :disabled="$v.$invalid"
                @click="sendOtp"
              >
                <span
                  >{{
                    sendCodeMethod === "email" ? "Email" : "Text"
                  }}
                  Code</span
                >
              </button>
            </div>
            <div class="button w-100 mt-3">
              <button
                @click="showSignIn()"
                type="button"
                class="btn btn-cancel w-100"
              >
                <span>Cancel</span>
              </button>
            </div>
            <div class="account-text">
              <p>
                Don't have an account?
                <button
                  type="button"
                  class="custom-underline-text"
                  @click="showSignUp()"
                >
                  <strong>Sign up</strong>
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  requiredIf,
  email,
  numeric,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "Internal-Forgot-Password",
  data() {
    return {
      email: "",
      phone: "",
      signInBg: "",
      sendCodeMethod: "email",
    };
  },
  validations: {
    email: {
      required: requiredIf(function () {
        return this.sendCodeMethod === "email";
      }),
      email,
    },
    phone: {
      required: requiredIf(function () {
        return this.sendCodeMethod === "message";
      }),
      numeric,
      minLength: minLength(10),
    },
  },
  mounted() {
    this.signInBg = this.getAssets.agentPortalBackground;
    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters([
      "getAssets",
      "getQuestions",
      "getCompanyName",
      "getProspectId",
      "getOrgId",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchForgotPassDetails",
      "fetchLoaderStatus",
      "fetchCompLoaderStatus",
    ]),

    showSignIn() {
      this.$emit("show-signin", true);
    },

    showSignUp() {
      this.$emit("show-signup", true);
    },

    showResetPassword() {
      this.$emit("show-resetpass", true);
    },

    changeMethod(method) {
      this.sendCodeMethod = method;

      if (method === "email") {
        this.phone = "";
        this.$v.$reset();
      }

      if (method === "message") {
        this.email = "";
        this.$v.$reset();
      }
    },

    async sendOtp() {
      this.fetchCompLoaderStatus(true);

      let value = this.sendCodeMethod === "email" ? this.email : this.phone;

      await this.$http
        .get(
          "/account/forgotPassword?emailOrPhone=" +
            value +
            "&msgOrEmail=" +
            this.sendCodeMethod +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success(resp.message);
            this.fetchForgotPassDetails(resp.prospectDetail);
            this.email = "";
            this.$v.$reset();
            this.showResetPassword();
          }

          if (resp.response === "Error") {
            this.err(resp.message);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });

      this.fetchCompLoaderStatus(false);
    },
  },
};
</script>

<style scoped>
.sign-in-up .form {
  margin: 45px 0 !important;
}

.text-left {
  text-align: left;
}

.sign-in-up.forgot-password .form .form__content form {
  padding: 30px 0px 0 !important;
}

.sign-in-up .form .form__content {
  padding: 0;
  max-width: 400px;
  margin: 0;
}

.forgot-password {
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-color);
  width: 100%;
  text-align: right;
}

.sign-in-up .form .form__content {
  border: none;
  box-shadow: none;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

form .flex-nowrap {
  margin-bottom: 10px;
}

form .flex-nowrap .form-label {
  margin-bottom: 0;
}

.custom-underline-text {
  text-decoration: underline;
  font-size: 14px;
}
</style>